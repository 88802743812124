import React, { useState } from 'react';
import { css } from 'styled-components/macro';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import theme from 'src/domains/root/styles/theme';
import { useSelector } from 'src/domains/root/store';
import {
  workspacesSelector,
  activeWorkspaceSelector,
  Workspace,
} from 'src/domains/root/features/users/slice';
import { Text } from 'src/shared/ui';
import { ListItemButton } from '@mui/material';
import WorkspaceMenuItem from './WorkspaceMenuItem';
import WorkspaceArrowDownIcon from './WorkspaceArrowDownIcon';
import WorkspaceArrowUpIcon from './WorkspaceArrowUpIcon';
import WorkspaceIcon from './WorkspaceIcon';

const emphasisStyle = css`
  & p {
    color: white;
    font-weight: bold;
  }
`;

const currentWorkspaceBoxStyle = css`
  height: 24px;
  margin-top: ${theme.spacing(1)};
`;

const currentWorkspaceListItemTextStyle = css`
  width: 182px;
`;

const currentWorkspaceTypographyStyle = css`
  font-weight: bold;
`;

const menuStyle = css`
  & .MuiPaper-rounded {
    left: 0 !important;
  }

  & .MuiPopover-paper {
    border-radius: 4px;
    box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.4);

    & ul div:last-child {
      margin-bottom: 0;
    }
  }
`;

const workspaceIconListItemStyle = css`
  margin-bottom: 18px;
  margin-top: 18px;
  padding-left: 17px;
  padding-right: 17px;
`;

const menuBoxStyle = css`
  border-radius: 4px;
  margin-bottom: ${theme.spacing(1)};
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};

  &:hover {
    background: ${theme.palette.primary.light};
  }

  & .MuiMenuItem-root.Mui-selected {
    ${emphasisStyle}

    background: ${theme.palette.primary.main};
  }
`;

interface WorkspaceInfoProps {
  drawerOpen: boolean;
  handleDrawerToggle?: () => void;
}

const WorkspaceInfo: React.FC<WorkspaceInfoProps> = (props) => {
  const intl = useIntl();
  const activeWorkspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<Workspace>;
  const workspaces = useSelector(workspacesSelector);

  const { drawerOpen, handleDrawerToggle } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const workspaceTitle = drawerOpen
    ? intl.formatMessage({ id: 'workspace.title' })
    : ' '; // blank character

  return (
    <>
      {drawerOpen ? (
        <ListItemButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            py: 2,
            pl: 2.5,
            pr: 2,
            width: '100%',
          }}
        >
          <Text
            variant="Title_A"
            color="textSecondary"
            sx={{ fontSize: { xs: 16 } }}
          >
            {workspaceTitle}
          </Text>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            css={currentWorkspaceBoxStyle}
          >
            <ListItemText css={currentWorkspaceListItemTextStyle}>
              <Typography noWrap css={currentWorkspaceTypographyStyle}>
                {activeWorkspace.workspaceName}
              </Typography>
            </ListItemText>
            <ListItemIcon style={{ minWidth: 0 }}>
              {anchorEl ? <WorkspaceArrowUpIcon /> : <WorkspaceArrowDownIcon />}
            </ListItemIcon>
          </Box>
        </ListItemButton>
      ) : (
        <ListItem button onClick={handleClick} css={workspaceIconListItemStyle}>
          <ListItemIcon>
            <WorkspaceIcon />
          </ListItemIcon>
        </ListItem>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        css={menuStyle}
      >
        {workspaces.map((workspace) => (
          <Box key={workspace.workspaceId} css={menuBoxStyle}>
            <WorkspaceMenuItem
              handleClose={handleClose}
              handleDrawerToggle={handleDrawerToggle}
              workspace={workspace}
              selected={activeWorkspace.workspaceId === workspace.workspaceId}
            />
          </Box>
        ))}
      </Menu>
    </>
  );
};

export default WorkspaceInfo;
