import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useFormikContext } from 'formik';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ContentHeader from 'src/domains/root/commons/settings/dialog/ContentHeader';
import { RootError } from 'src/domains/root/utils/api';
import {
  AreasErrorCode,
  AreaData,
} from 'src/domains/root/features/areas/slice';
import HookStatus from 'src/domains/root/commons/HookStatus';
import { Location } from '../LocationSettingModal';
import CityInitialDividerIcon from './CityInitialDividerIcon';

const gatewayNameTypographyStyle = css`
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.spacing(3.5)};
  padding-top: ${(props) => props.theme.spacing(2.5)};
`;

const cityInitialTypographyStyle = css`
  font-weight: bold;
  margin-right: ${(props) => props.theme.spacing(1.5)};
`;

interface StyledPrefectureButtonProps {
  prefectureName: string;
  valuesPrefecture: string | undefined;
}

const StyledPrefectureButton = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ prefectureName, valuesPrefecture, ...props }) => <Button {...props} />,
).attrs(() => ({
  varinat: 'text',
}))<StyledPrefectureButtonProps>`
  padding: ${(props) => props.theme.spacing(0.5)}
    ${(props) => props.theme.spacing(1)};

  margin-right: ${(props) => props.theme.spacing(3.5)};
  margin-bottom: ${(props) => props.theme.spacing(3)};

  background-color: ${(props) => {
    return props.prefectureName === props.valuesPrefecture
      ? `${props.theme.palette.primary.main}`
      : 'white';
  }};

  color: ${(props) => {
    return props.prefectureName === props.valuesPrefecture
      ? 'white'
      : `${props.theme.palette.text.primary}`;
  }};

  font-weight: ${(props) => {
    return props.prefectureName === props.valuesPrefecture ? 'bold' : 'normal';
  }};

  &:hover {
    background-color: ${(props) => {
      return props.prefectureName === props.valuesPrefecture
        ? `${props.theme.palette.nito.main}`
        : `${props.theme.palette.primary.light}`;
    }};
  }
`;

interface StyledAreaButtonProps {
  areaPinpointName: string;
  valuesArea: string | undefined;
}

const StyledAreaButton = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ areaPinpointName, valuesArea, ...props }) => <Button {...props} />,
).attrs(() => ({
  varinat: 'text',
}))<StyledAreaButtonProps>`
  padding: ${(props) => props.theme.spacing(0.5)}
    ${(props) => props.theme.spacing(1)};

  margin-right: ${(props) => props.theme.spacing(3.5)};
  margin-bottom: ${(props) => props.theme.spacing(3)};

  background-color: ${(props) => {
    return props.areaPinpointName === props.valuesArea
      ? `${props.theme.palette.primary.main}`
      : 'white';
  }};

  color: ${(props) => {
    return props.areaPinpointName === props.valuesArea
      ? 'white'
      : `${props.theme.palette.text.primary}`;
  }};

  font-weight: ${(props) => {
    return props.areaPinpointName === props.valuesArea ? 'bold' : 'normal';
  }};

  &:hover {
    background-color: ${(props) => {
      return props.areaPinpointName === props.valuesArea
        ? `${props.theme.palette.nito.main}`
        : `${props.theme.palette.primary.light}`;
    }};
  }
`;

const regionBreadCrumbsBoxStyle = css`
  cursor: pointer;
`;

const regionBreadCrumbsTypographyStyle = css`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  font-size: ${(props) => props.theme.typography.pxToRem(12)};
`;

const prefectureBreadCrumbsTypographyStyle = css`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.pxToRem(12)};
`;

const breadcrumbsStyle = css`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  margin-bottom: ${(props) => props.theme.spacing(3)};
`;

const dialogContentStyle = css`
  padding-top: 0;
  padding-bottom: 0;
`;

interface ModalContentProps {
  gatewayName: string;
  areas: AreaData[];
  modalRef: React.RefObject<HTMLDivElement>;
  isRegionPage: boolean;
  toggleIsRegionPage: () => void;
  error: RootError<AreasErrorCode> | null;
  loading: boolean;
}

const ModalContent: React.FC<ModalContentProps> = (props) => {
  const {
    gatewayName,
    areas,
    modalRef,
    isRegionPage,
    toggleIsRegionPage,
    error,
    loading,
  } = props;
  const { values, setValues } = useFormikContext<Location>();

  const handlePrefectureClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setValues({
      ...values,
      region: e.currentTarget.dataset['region'] as string,
      prefecture: e.currentTarget.value,
    });
    toggleIsRegionPage();
  };

  const handleAreaClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setValues({
      ...values,
      areaId: e.currentTarget.dataset['areaId'] as string,
      area: e.currentTarget.value,
    });
  };

  const regionAndPrefecturePage = (
    <>
      <Typography
        component="h1"
        css={gatewayNameTypographyStyle}
        sx={{ wordBreak: 'break-all' }}
      >
        {gatewayName}
      </Typography>
      {areas.map((areaData) => {
        return (
          <Box key={areaData.region}>
            <Box p={0} mb={3}>
              <ContentHeader>{areaData.region}</ContentHeader>
            </Box>
            <Box pl={4}>
              {areaData.prefectures.map((prefecture) => {
                return (
                  <StyledPrefectureButton
                    data-testid={`${prefecture.name}`}
                    key={prefecture.name}
                    prefectureName={prefecture.name}
                    valuesPrefecture={values.prefecture}
                    onClick={handlePrefectureClick}
                    value={prefecture.name}
                    data-region={areaData.region}
                  >
                    {prefecture.name}
                  </StyledPrefectureButton>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </>
  );

  const areaPageContent =
    areas.length !== 0 &&
    values.region !== '' &&
    values.prefecture !== '' &&
    values.prefecture !== 'noLocation'
      ? areas
          .filter((areaData) => areaData.region === values.region)[0]
          .prefectures.filter(
            (prefecture) => prefecture.name === values.prefecture,
          )[0]
          .cities.map((city) => {
            return (
              <Box key={city.initial}>
                <Box display="flex" alignItems="center" pl={1} height={13}>
                  <Typography css={cityInitialTypographyStyle}>
                    {city.initial}
                  </Typography>
                  <CityInitialDividerIcon />
                </Box>
                <Box pt={2} pl={4}>
                  {city.areas.map((area) => {
                    return (
                      <StyledAreaButton
                        data-testid={`${area.pinpointName}`}
                        key={area.areaId}
                        areaPinpointName={area.pinpointName}
                        valuesArea={values.area}
                        onClick={handleAreaClick}
                        value={area.pinpointName}
                        data-area-id={area.areaId}
                        name="area"
                      >
                        {area.pinpointName}
                      </StyledAreaButton>
                    );
                  })}
                </Box>
              </Box>
            );
          })
      : null;

  const areaPage = (
    <>
      {!isRegionPage && (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" color="primary" />}
          aria-label="breadcrumb"
          css={breadcrumbsStyle}
        >
          <Box onClick={toggleIsRegionPage} css={regionBreadCrumbsBoxStyle}>
            <Typography css={regionBreadCrumbsTypographyStyle}>
              {values.region}
            </Typography>
          </Box>
          <Typography css={prefectureBreadCrumbsTypographyStyle}>
            {values.prefecture}
          </Typography>
        </Breadcrumbs>
      )}
      {areaPageContent}
    </>
  );

  if (error || loading) {
    return (
      <DialogContent css={dialogContentStyle}>
        <HookStatus
          target="gatewayLocationData"
          error={!!error}
          loading={loading}
        />
      </DialogContent>
    );
  }

  return (
    <DialogContent css={dialogContentStyle}>
      <div ref={modalRef}>
        <>{isRegionPage ? regionAndPrefecturePage : areaPage}</>
      </div>
    </DialogContent>
  );
};

export default ModalContent;
