import React, { useEffect } from 'react';
import { Dialog as _Dialog, DialogProps } from '@mui/material';
import { awsRum } from 'src/utils/rum';

type Props = {
  open: boolean;
  loggingId: string;
  children: React.ReactNode;
} & DialogProps;

export function Dialog({ open, loggingId, children, ...props }: Props) {
  useEffect(() => {
    if (open) {
      awsRum().then((rum) => {
        rum.recordPageView(location.pathname + '#' + loggingId);
      });
    }
  }, [open, loggingId]);

  return (
    <_Dialog open={open} {...props}>
      {children}
    </_Dialog>
  );
}
