import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import * as API from 'src/apis';
import { useSelector } from 'src/domains/root/store';
import { awsRum } from 'src/utils/rum';
import { activeWorkspaceSelector } from '../../users/slice';

type ErrorCode =
  | 'np_error'
  | 'bad_request'
  | 'not_found'
  | 'permission_denied'
  | 'emergency_maintenance'
  | 'unknown_error';

type MutationProps = API.WorkspaceInvoicePaymentActivating;

type Response = void;

export type Result = {
  mutate: ({
    companyName,
    zipCode,
    address,
    telNo,
    email,
  }: MutationProps) => Promise<Response>;
  reset: () => void;
  data?: Response;
} & (
  | {
      status: 'hasNpError';
      errorCode: 'np_error';
      npErrorCodes?: API.NPErrorCode[];
    }
  | { status: 'hasError'; errorCode: ErrorCode }
  | { status: 'loading' }
  | { status: 'idle' }
  | { status: 'succeeded' }
);

export function usePutWorkspacesPaymentsInvoicesActivating({
  onError,
}: {
  onError: () => void;
}): Result {
  const { getAccessTokenSilently } = useAuth0();
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<API.Workspace>;
  const workspaceId = workspace.workspaceId;

  const mutation = useMutation<Response, API.Error, MutationProps>({
    mutationFn: async ({ companyName, zipCode, address, telNo, email }) => {
      const token = await getAccessTokenSilently();

      const response = await API.putWorkspacesPaymentsInvoicesActivating({
        workspaceId,
        workspaceInvoicePaymentActivating: {
          companyName,
          zipCode,
          address,
          telNo,
          email,
        },
        token,
      });

      return response;
    },
    onError,
  });

  const responseBase = {
    mutate: async ({
      companyName,
      zipCode,
      address,
      telNo,
      email,
    }: MutationProps) => {
      const result = await mutation.mutateAsync({
        companyName,
        zipCode,
        address,
        telNo,
        email,
      });
      return result;
    },
    reset: mutation.reset,
    data: mutation.data,
  };

  if (mutation.isIdle) {
    return {
      ...responseBase,
      status: 'idle',
    };
  }

  if (mutation.isPending) {
    return {
      ...responseBase,
      status: 'loading',
    };
  }

  if (mutation.isError) {
    const error = mutation.error as API.Error;

    switch (error.code) {
      case 'np_error':
        return {
          ...responseBase,
          status: 'hasNpError',
          errorCode: 'np_error',
          npErrorCodes: error.npErrorCodes,
        };
      case 'bad_request':
      case 'permission_denied':
      case 'emergency_maintenance':
      case 'not_found':
        return {
          ...responseBase,
          status: 'hasError',
          errorCode: error.code,
        };
      case 'unknown_error':
      default:
        awsRum().then((rum) => rum.recordError(error)); // awaitせずに捨てる。プロダクトに影響を与えないようにするため。
        return {
          ...responseBase,
          status: 'hasError',
          errorCode: 'unknown_error',
        };
    }
  }

  return {
    ...responseBase,
    status: 'succeeded',
  };
}
