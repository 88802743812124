import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Box, Dialog, DialogTitle, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import * as yup from 'yup';
import Title from 'src/domains/root/commons/dialog/Title';
import DialogContent from 'src/domains/root/commons/dialog/DialogContent';
import DialogActions from 'src/domains/root/commons/dialog/DialogActions';
import ValidationBox from 'src/domains/root/commons/form/ValidationBox';
import CancelButton from 'src/domains/root/commons/dialog/actions/CancelButton';
import SubmitButton from 'src/domains/root/commons/form/SubmitButton';
import useSnackbar from 'src/domains/root/commons/snackbar';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { Gateway } from 'src/domains/root/features/gateways/slice';
import { AppDispatch } from 'src/domains/root/store';
import { useLocation } from 'react-router-dom';
import { awsRum } from 'src/utils/rum';
import { useCheckFeatureFlag } from 'src/utils/feature-flag/FeatureFlag';
import { RootError } from '../../utils/api';
import { postPayments } from './paymentSlice';

type Values = {
  name: string;
};

export default function CustomerUpdateDialog({
  open,
  close,
  gateway,
  updateGatewaysPaymentsInvoceReview,
}: {
  open: boolean;
  close: () => void;
  gateway: Gateway;
  updateGatewaysPaymentsInvoceReview?: (gateways: Gateway[]) => void;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();
  const isPaymentMethodFeatureEnabled = useCheckFeatureFlag(
    'contract.payment-method',
  );

  const { enqueueSnackbar: enqueuePaymentErrorSnackbar } = useSnackbar(
    'SNACKBAR_PAYMENTS_ERROR',
    { noRefreshButton: true },
  );
  const { enqueueSnackbar: enqueueAfterPaymentInfoSnackbar } = useSnackbar(
    'SNACKBAR_AFTER_PAYMENTS_INFO',
    {
      enqueueOptions: {
        variant: 'info',
      },
    },
  );

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(intl.formatMessage({ id: 'validation.mixed.required' }))
      .max(200, intl.formatMessage({ id: 'validation.string.max' })),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { name } = values;

      if (name === '') {
        throw new Error('name is empty');
      }

      const token = getAccessTokenSilently();

      const paymentPage = window.open('/loading.html', '_blank');

      dispatch(
        postPayments({
          token,
          gateways: [gateway],
          customerName: name,
          cabiotPaymentType: 'stripe',
          isPaymentMethodFeatureEnabled,
        }),
      )
        .unwrap()
        .then((payment) => {
          if (paymentPage) paymentPage.location.href = payment.url;
          enqueueAfterPaymentInfoSnackbar(
            'pages.OtherSetting.contracts.list.payments.info.afterPayment',
          );
        })
        .catch((e) => {
          paymentPage?.close();

          if (
            isPaymentMethodFeatureEnabled &&
            e.code === 'already_review_np_invoice' &&
            updateGatewaysPaymentsInvoceReview
          ) {
            updateGatewaysPaymentsInvoceReview([gateway]);
          }

          const error = e as RootError;
          if (!error.noticeable) {
            return;
          }
          enqueuePaymentErrorSnackbar(
            `pages.OtherSetting.contracts.list.payments.error.${error.code}`,
          );
        })
        .finally(() => {
          close();
        });
    },
    enableReinitialize: false,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const location = useLocation();
  useEffect(() => {
    if (open && !isPaymentMethodFeatureEnabled) {
      awsRum().then((rum) => {
        rum.recordPageView(
          location.pathname + '#StripeCustomerUpdateDialogRender',
        );
      });
    }
  }, [location, open]);

  return (
    <DialogWrapper
      open={open}
      isPaymentMethodFeatureEnabled={isPaymentMethodFeatureEnabled}
    >
      <DialogTitle>
        <Title onClose={close}>
          {intl.formatMessage({
            id: 'pages.OtherSetting.contracts.list.stripeCustomerUpdateDialog.title',
          })}
        </Title>
      </DialogTitle>
      <FormikProvider value={formik}>
        <Form>
          <DialogContent>
            <Box pt={1} pb={{ xs: 0, sm: 3.5 }}>
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.list.stripeCustomerUpdateDialog.content.description',
              })}
              <Box pt={3} px={2}>
                <NameField />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              p: 3.5,
              flexDirection: {
                xs: 'column-reverse',
                sm: 'row',
              },
              rowGap: {
                xs: 1.5,
                sm: 'unset',
              },
              columnGap: {
                sm: 1.5,
              },
              '& > :not(:first-of-type)': {
                ml: 0,
              },
            }}
          >
            <CancelButton
              onClick={close}
              disabled={formik.isSubmitting}
              sx={{
                width: {
                  xs: '100%',
                  sm: 'unset',
                },
              }}
            />
            <SubmitButton
              withLoading
              sx={{
                px: 2.75,
                width: {
                  xs: '100%',
                  sm: 'unset',
                },
              }}
            >
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.list.stripeCustomerUpdateDialog.form.submitButton',
              })}
            </SubmitButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </DialogWrapper>
  );
}

function DialogWrapper({
  open,
  isPaymentMethodFeatureEnabled,
  children,
}: {
  open: boolean;
  isPaymentMethodFeatureEnabled: boolean;
  children: React.ReactNode;
}) {
  return isPaymentMethodFeatureEnabled ? (
    <>{children}</>
  ) : (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '.MuiDialog-paper ': {
          maxWidth: 572,
        },
      }}
    >
      {children}
    </Dialog>
  );
}

function NameField() {
  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setTouched,
    isSubmitting,
  } = useFormikContext<Values>();

  const handleFocus = () => {
    setTouched({ ...touched, name: true });
  };

  return (
    <ValidationBox mb={0}>
      <TextField
        name="name"
        type="text"
        value={values.name}
        label={intl.formatMessage({
          id: 'pages.OtherSetting.contracts.list.stripeCustomerUpdateDialog.form.nameTextField.label',
        })}
        placeholder={intl.formatMessage({
          id: 'pages.OtherSetting.contracts.list.stripeCustomerUpdateDialog.form.nameTextField.placeholder',
        })}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
        disabled={isSubmitting}
        variant="outlined"
        size="small"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          sx: { height: 45 },
        }}
      />
    </ValidationBox>
  );
}
