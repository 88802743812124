import { useDispatch } from 'react-redux';
import { activeWorkspacePaymentsSelector } from 'src/domains/root/features/users/slice';
import { useCallback, useEffect } from 'react';
import * as API from 'src/apis';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useCheckFeatureFlag } from 'src/utils/feature-flag/FeatureFlag';
import { AppDispatch, useSelector } from 'src/domains/root/store';
import useGateways from 'src/domains/root/features/gateways/useGateways';
import { activeWorkspaceSelector } from 'src/domains/root/features/users/slice';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import { type Gateway } from 'src/domains/root/features/gateways/slice';
import { useGetWorkspacesGatewaysPaymentsInvoices } from './useGetWorkspacesGatewaysPaymentsInvoices';
import {
  PostPaymentsParams,
  createResultSelector,
  postPayments,
  resetCreateResult,
} from './paymentSlice';

export default function useContractTableModel() {
  const queryClient = useQueryClient();
  const dispatch: AppDispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const isPaymentMethodFeatureEnabled = useCheckFeatureFlag(
    'contract.payment-method',
  );

  const {
    gateways,
    error: gatewaysError,
    status: gatewaysStatus,
  } = useGateways();
  const workspace = useSelector(
    activeWorkspaceSelector,
  ) as NonNullable<API.Workspace>;
  const paymentsInvoicesReviewResult = useGetWorkspacesGatewaysPaymentsInvoices(
    workspace,
    'review',
  );
  const paymentsInvoicesReviewKey = Keys.payments.invoices(
    workspace.workspaceId,
    'review',
  );

  const paymentsResult = useSelector(createResultSelector);
  const payments = useSelector(activeWorkspacePaymentsSelector);

  const updateGatewaysPaymentsInvoceReview = (gateways: Gateway[]) => {
    queryClient.setQueryData(
      paymentsInvoicesReviewKey,
      (oldData: API.GetWorkspacesGatewaysPaymentsInvoicesResponse) => ({
        gatewayIds: [
          ...oldData.gatewayIds,
          ...gateways.map((gateway) => gateway.deviceId),
        ],
      }),
    );
  };

  const submitPayments = async ({
    cabiotPaymentType,
    gateways,
    customerName,
  }: PostPaymentsParams) => {
    const token = getAccessTokenSilently();

    return dispatch(
      postPayments({
        token,
        cabiotPaymentType,
        gateways,
        customerName,
        isPaymentMethodFeatureEnabled,
      }),
    )
      .unwrap()
      .then(() => {
        updateGatewaysPaymentsInvoceReview(gateways);
      })
      .catch((error) => {
        if (error.code === 'already_review_np_invoice') {
          updateGatewaysPaymentsInvoceReview(gateways);
        }
      });
  };
  const resetPaymentsResult = useCallback(() => {
    dispatch(resetCreateResult());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      resetPaymentsResult();
    };
  }, [resetPaymentsResult]);

  return {
    gateways,
    gatewaysError,
    gatewaysStatus,
    payments,
    paymentsResult,
    submitPayments,
    resetPaymentsResult,
    paymentsInvoicesReviewResult,
    updateGatewaysPaymentsInvoceReview,
  };
}
