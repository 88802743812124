import React from 'react';
import { DialogTitle as _DialogTitle, Box } from '@mui/material';
import { Text } from 'src/shared/ui';
import CloseIconButton from './title/CloseIconButton';

type Props = {
  children: React.ReactNode;
  onClose: () => void;
};

export function DialogTitle({ children, onClose }: Props) {
  return (
    <_DialogTitle>
      <Box position="relative">
        <Text fontWeight="bold" fontSize={18} pr={3} color="primary">
          {children}
        </Text>
        <CloseIconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: -8, right: -12 }}
        />
      </Box>
    </_DialogTitle>
  );
}
