import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import useSmallScreen from 'src/domains/invitation/hooks/use-small-screen';

const BracketList = styled('ol')`
  counter-reset: ol;
  list-style: none;
  padding-left: 4rem;

  & li::before {
    content: '(' counter(ol) ')';
    counter-increment: ol;
    display: inline-block;
    margin-left: -2rem;
    width: 2rem;
  }
`;
const H1 = styled('h1')(({ theme }) =>
  theme.unstable_sx({
    fontSize: 22,
    textAlign: { xs: 'left', sm: 'center' },
  }),
);
const H2 = styled('h1')(({ theme }) =>
  theme.unstable_sx({
    fontSize: { xs: 18, sm: 14 },
  }),
);
const Section = styled('section')(({ theme }) =>
  theme.unstable_sx({
    fontSize: { xs: 14, sm: 12 },
    lineHeight: 1.6,
  }),
);

type Props = {
  setIsTermsOfServiceScrollEnd: Dispatch<SetStateAction<boolean>>;
};

const TermsOfServiceDocument = (props: Props) => {
  const { setIsTermsOfServiceScrollEnd } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const isSmallScreen = useSmallScreen();

  useEffect(() => {
    const element = wrapperRef.current;

    function checkScrollEnd(element: HTMLDivElement | null) {
      if (!element) return;

      // 解像度によって微妙な誤差があるので10pxぐらいの余裕に計算する
      Math.ceil(element.scrollTop + element.offsetHeight) >=
        element?.scrollHeight - 10 && setIsTermsOfServiceScrollEnd(true);
    }

    if (!element) return;

    element.addEventListener('scroll', () => checkScrollEnd(element));

    return element.removeEventListener('scroll', () => checkScrollEnd(element));
  }, [setIsTermsOfServiceScrollEnd]);

  return (
    <Box
      ref={wrapperRef}
      data-testid="TermsOfServiceDocument"
      py={isSmallScreen ? 0 : 1}
      px={isSmallScreen ? 2 : 4}
      margin="0 auto"
      overflow="scroll"
      maxWidth={720}
      minHeight={348}
      maxHeight={isSmallScreen ? 348 : 520}
      bgcolor="#F3F2F2"
      sx={{
        overflowWrap: 'anywhere',
      }}
    >
      <H1>CABIoTサービス利用規約</H1>
      <Section>
        <H2>第１条（総則）</H2>
        <ol>
          <li>
            この利用規約（以下、「本規約」といいます。）は、日東工業株式会社（以下、「当社」といいます。）が、当社がお客様(以下、「ユーザー」といいます。）へ販売したCABIoTサービス（以下、「本サービス」といいます。）を構成するデバイス機器（以下、「本デバイス」といいます。）、およびクラウドサービス（以下、「本クラウド」といいます。）の利用条件を定めるものです。
          </li>
          <li>
            本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          </li>
          <li>
            当社は本サービスに関し、本規約のほか、利用にあたってのルール等、各種の規定（以下、「個別規定」といいます。）を定めることがあります。これらの個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の規定が前項の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第２条（サービスの内容）</H2>
        <ol>
          <li>
            本サービスは、本デバイスを利用した環境データの計測、本クラウドによる遠隔監視を提供します。
          </li>
          <li>
            契約期間中に当社の定める所定の更新の手続きがない場合、契約期間終了後に本サービスの契約は終了するものとします。この場合、本サービスに登録されたユーザー情報、第5条に定める計測データおよび第6条に定める利用データは削除されるものとします。
          </li>
          <li>
            本サービスの提供を受けるには通信機能付きの本デバイスの利用が必要であり、本規約に加えてSORACOMAirサービス契約約款
            (
            <a
              href="https://soracom.jp/share/terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://soracom.jp/share/terms/
            </a>
            )
            の適用を受け、本サービス利用開始をもってSORACOMAirサービス契約約款（変更される場合は変更後のものを含みます。）に同意するものとします。
          </li>
          <li>
            ユーザーが本デバイスを使用するにあたり、許認可、認証、届出等が必要となるときは、ユーザーの費用と責任で処理解決して頂くものとします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第３条（ユーザー登録）</H2>
        <p>
          当社は、本サービスへの登録（以下、「ユーザー登録」といいます。）を申請するユーザーに以下の事由があると判断した場合、当該申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
        </p>
        <BracketList>
          <li>申請に際して虚偽の事項を届け出た場合</li>
          <li>本規約に違反したことがある者からの申請である場合</li>
          <li>その他、当社がユーザー登録を相当でないと判断した場合</li>
        </BracketList>
      </Section>
      <Section>
        <H2>第４条（ユーザーIDおよびパスワードの管理）</H2>
        <ol>
          <li>
            本サービスにおいては、ユーザーが本規約に同意の上、当社の定める方法によってユーザー登録を申請し、当社が当該申請を承認した場合、利用を開始することができます。
          </li>
          <li>
            本サービスを利用できる対象者は、本サービスを購入したユーザーに限られるものとし、ユーザーは、別途事前に当社から書面による承諾を取得しない限り第三者に本サービスを利用させてはなりません。
          </li>
          <li>
            前項の規定にかかわらず、ユーザーは、ユーザーの従業員のほか、(i)ユーザーの親会社、子会社、関連会社及びそれらの会社の従業員、(ii)本デバイスがデータを計測又は監視している機器に関するメンテナンス業務その他の関連業務についてユーザーが委託した第三者並びに(iii)本デバイス及び本サービスの販売代理店に対し、本サービスを利用させることができるものとします。この場合、ユーザーは、これらの者に対して本契約におけるユーザーの義務と同様の義務を課すものとし、また、ユーザーは、これらの者による本サービスの利用に関して、自己の利用による場合と同一の責任を負うものとします。
          </li>
          <li>
            ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に使用及び管理し、これを第三者に伝達、譲渡、貸与しないものとします（但し、前項に基づき、ユーザー以外の者が本サービスを利用する場合において、これらの者に対して本サービスのユーザーIDおよびパスワードの伝達をする場合を除きます。）。なお、当社はユーザーID及びパスワードの入力者をユーザー本人とみなして本規約を適用します。
          </li>
          <li>
            本サービスのユーザーID及びパスワードが第三者によって使用されたことによって生じた損害については、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第５条（計測データの管理）</H2>
        <ol>
          <li>
            本サービスで蓄積したセンサーにより取得された温度、湿度等の計測データ(以下、「計測データ」といいます。)の消去、バックアップ、維持管理はユーザーの責任で行うものとします。
          </li>
          <li>
            本サービスの利用で発生した計測データの破損、消失など、およびそれによって発生するユーザーまたは第三者が被ったいかなる不利益または損害についても、当社およびインフラ提供者はいかなる責任も負わないものとします。
          </li>
          <li>
            当社は、以下の目的の達成に必要な範囲で、ユーザーの事前の承諾を得ることなく計測データにアクセスし、利用できるものとします。
            <BracketList>
              <li>本サービスの提供</li>
              <li>
                ユーザーへのサポート提供および本サービスを利用した営業支援の提供
              </li>
              <li>本サービスの管理保守、不具合の対応</li>
              <li>
                本サービスの改善、新サービスその他関連するサービスや機能の開発
              </li>
              <li>当社が提供する商品・サービスに関する情報のご案内</li>
            </BracketList>
          </li>
          <li>
            当社は、計測データについて、前項各号の目的の達成に必要な範囲で、開発業務委託先、サポート業務委託先、インフラ提供者等（以下、「サードパーティーパートナー」といいます。）に提供することができるものとします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第６条（利用データの取り扱い）</H2>
        <ol>
          <li>
            当社は、ユーザーの本デバイス使用についての位置情報、端末識別番号、通信の識別番号などの情報（以下、「利用データ」といいます。）を本デバイスにて計測されたデータをユーザーの通信端末に紐づけて識別するために取得します。
          </li>
          <li>
            当社は、ユーザーの利用データについて、前条3項各号の目的の達成に必要な範囲で、サードパーティーパートナーに提供することができるものとします。
          </li>
          <li>
            当社は、利用データを運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社はかかる情報の削除によってユーザーに生じた損害について一切の責任を負いません。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第７条（契約期間）</H2>
        <ol>
          <li>本サービスの契約期間は契約成立日から1年間とします。</li>
          <li>
            契約期間の終了１か月前に当社からユーザーへ契約期間終了が近いことを連絡し、契約期間終了日までにユーザーが当社の定める契約更新の手続きを行った場合、本サービスの契約期間は1年間延長されるものとし、以降も同様とします。
          </li>
          <li>
            前項の契約更新の手続きを行わなかった場合、本サービスの提供は契約期間満了と共に終了するものとします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第８条（中途解約）</H2>
        <ol>
          <li>ユーザーは本サービスを契約期間中に解約できないものとします。</li>
          <li>
            前項にかかわらず、やむを得ない事情がある場合であって、かつ当社の同意があるときに限り、ユーザーは本サービスを契約期間中に解約できるものとします。ただし、この場合ユーザーの支払い済みの利用料金は返金されないものとします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第９条（禁止事項）</H2>
        <p>
          ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        </p>
        <BracketList>
          <li>法令または公序良俗に違反するおそれのある行為</li>
          <li>犯罪行為に関連するおそれのある行為</li>
          <li>
            本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害するおそれのある行為
          </li>
          <li>
            当社、本サービスのユーザーまたはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりするおそれのある行為
          </li>
          <li>本サービスによって得られた情報を商業的に利用する行為</li>
          <li>
            当社のサービスの運営を妨害し、または当社の信用を毀損するおそれのある行為
          </li>
          <li>
            当社、本サービスのユーザーまたはその他の第三者の財産権、プライバシー、名誉その他の権利を侵害し、または侵害するおそれのある行為
          </li>
          <li>当社のサービスに不正アクセスをし、またはこれを試みる行為</li>
          <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
          <li>不正な目的を持って本サービスを利用する行為</li>
          <li>
            当社、本サービスのユーザーまたはその他の第三者を誹謗、中傷、脅迫、その他不利益、損害、不快感を与えるおそれのある行為
          </li>
          <li>本サービスのユーザーID及びパスワードを不正に使用する行為</li>
          <li>
            本サービスを購入したユーザー以外の者に本サービスを利用させる行為（但し、第4条第3項及び第4項に基づく利用を除く。）
          </li>
          <li>他のユーザーに成りすます行為</li>
          <li>
            コンピューターウイルス等有害なプログラムを使用または提供する行為
          </li>
          <li>リバースエンジニアリングを行う行為</li>
          <li>
            当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
          </li>
          <li>その他、当社が不適切と判断する行為</li>
        </BracketList>
      </Section>
      <Section>
        <H2>第10条（本サービスの提供の停止等）</H2>
        <ol>
          <li>
            当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
            <BracketList>
              <li>
                本サービスにかかるコンピューターシステム等の保守点検または更新を行う場合
              </li>
              <li>
                地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
              </li>
              <li>コンピューターまたは通信回線等が事故等により停止した場合</li>
              <li>本サービスにかかわる設備にやむを得ない障害が発生した場合</li>
              <li>本サービスの運営上必要と当社が判断する場合</li>
              <li>
                その他、（1）から（5）に準ずる事由により、当社が本サービスの提供が困難と判断した場合
              </li>
            </BracketList>
          </li>
          <li>
            当社は、当社の都合により、本サービスの提供を終了又は休止（前項各号の事由による停止及び中断は含まれないものとし、以下本項においても同様とします。）することができます。この場合、当社はユーザーに終了又は休止の1か月前までに電子メール、WEBページまたはその他当社の定める方法で通知するものとします。ただし、緊急を要する場合はこの限りではありません。
          </li>
          <li>
            当社は、本サービスの提供の停止、中断、終了により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第11条（利用制限および登録抹消）</H2>
        <ol>
          <li>
            当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限もしくは停止し、またはユーザーとしての登録を抹消することができるものとします。
            <BracketList>
              <li>本規約のいずれかの条項に違反した場合</li>
              <li>登録事項に虚偽の事実があることが判明した場合</li>
              <li>当社からの連絡に対し、一定期間返答がない場合</li>
              <li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
            </BracketList>
          </li>
          <li>
            当社は、前項に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第12条（免責事項）</H2>
        <ol>
          <li>
            当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的又は黙示的に保証するものではありません。
          </li>
          <li>
            当社は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
          </li>
          <li>
            当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち、特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。
          </li>
          <li>
            本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等については、ユーザーが自らの責任において解決するものとし、当社は一切責任を負いません。
          </li>
          <li>
            本サービスは利用者のハードウェア、ネットワーク等による影響や通信設備、電波状況や回線状況等の影響を受けるものであり、計測データ取得の完全性、本サービスの完全性および有用性を保証するものではありません。
          </li>
          <li>
            登録情報の不備によりユーザーが不利益を被った場合でも、当社はその責任を負わないものとします。
          </li>
          <li>
            本規定に基づいた本サービス提供の提供停止や解約により損害が生じた場合であっても当社はその責任を負いません。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第13条（サービス内容の変更等）</H2>
        <p>
          当社は、1か月前までに電子メール、WEBページまたはその他当社の定める方法にてユーザーに通知することにより、本サービスの内容や機能を改善あるいは変更することができるものとします。
          ただし、緊急を要する場合はこの限りではありません。
          <br />
          また、本サービスの内容や機能の改善あるいは変更によってユーザーに損害が生じた場合でも当社は一切の責任を負いません。
        </p>
      </Section>
      <Section>
        <H2>第14条（知的財産権）</H2>
        <p>
          本サービスに関する著作権、特許権、商標権その他一切の知的財産権は当社に帰属し、ユーザーは、これらを無断で複製、転載、改変、その他の二次利用をすることはできません。
        </p>
      </Section>
      <Section>
        <H2>第15条（利用規約の変更）</H2>
        <ol>
          <li>
            当社は、当社が必要と判断した場合には、ユーザーに個別に通知することなくいつでも本規約を変更することができるものとします。その場合、当社は本規約の変更の効力発生日を定めるとともに、当該効力発生日の1か月以上前までに、当社のホームページに利用規約を変更する旨及び変更後の利用規約の内容並びにその効力発生時期を掲載します。
          </li>
          <li>
            前項に定める本規約の変更の効力発生日以降にユーザーが本サービスを利用した場合、本規約の変更に同意したものとみなします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第16条（個人情報の取扱い）</H2>
        <p>
          当社は，本サービスの利用によって取得する個人情報については、当社ホームページに掲載する
          「プライバシーポリシー」 (
          <a
            href="https://www.nito.co.jp/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.nito.co.jp/privacy/
          </a>
          ) に従い適切に取り扱うものとします。
        </p>
      </Section>
      <Section>
        <H2>第17条（通知または連絡）</H2>
        <p>
          ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから、当社が別途定める方法に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
        </p>
      </Section>
      <Section>
        <H2>第18条（利用環境）</H2>
        <p>提供する本サービスの推奨利用環境は以下の通りです。</p>
        <blockquote>ウェブブラウザー：Google Chrome最新版</blockquote>
      </Section>
      <Section>
        <H2>第19条（反社会的勢力の排除）</H2>
        <ol>
          <li>
            ユーザーが次の各号の一に該当する場合、当社はユーザーに対して何らの催告をすることなく、本サービスの提供を中止し、当該ユーザーの登録、第5条に定める計測データおよび第6条に定める利用データを削除することができるものとします。
            <BracketList>
              <li>
                自社の役職員が、暴力団、暴力団員、暴力団関係企業・団体または暴力団関係者、総会屋、社会運動等標ぼうゴロ、その他の反社会的勢力（以下、「反社会的勢力」といいます。）である場合、または反社会的勢力であった場合
              </li>
              <li>自社の役職員が、反社会的勢力を利用した場合</li>
              <li>
                自社の役職員が、反社会的勢力に対して資金等を提供し、または便宜を供給するなど反社会的勢力の維持運営に協力し、または関与した場合
              </li>
              <li>
                自社の役職員が、反社会的勢力と社会的に非難されるべき関係を有していると認められる場合
              </li>
              <li>
                自社の役職員が自らまたは第三者を利用して、相手方に対し暴力的行為、詐術、脅迫的言辞を用い、相手方の名誉や信用を毀損し、または相手方の業務を妨害した場合
              </li>
            </BracketList>
          </li>
          <li>
            ユーザーは、前項各号を確認することを目的として当社が行う調査に協力するものとします。
          </li>
          <li>
            ユーザーは、第１項各号のいずれかに該当し、またはそのおそれがあることが判明した場合、直ちにその旨を当社に通知するものとします。
          </li>
        </ol>
      </Section>
      <Section>
        <H2>第20条（債権、債務の譲渡等）</H2>
        <p>
          ユーザーは、当社の文書による事前の承諾がない限り、本サービスの全部または一部を第三者に売却、譲渡、移転、または担保に供してはならないものとします。
        </p>
      </Section>
      <Section>
        <H2>第21条（管轄裁判所）</H2>
        <p>
          本サービスに関する一切の紛争については、当社の本店所在地を管轄する地方裁判所を第一審の専属的合意管轄裁判所として処理するものとします。
        </p>
      </Section>
      <div
        style={{
          marginTop: '36px',
          float: 'right',
        }}
      >
        制定日：2023年4月1日
      </div>
    </Box>
  );
};

export default TermsOfServiceDocument;
