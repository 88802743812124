import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import * as API from 'src/apis';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import {
  CancelButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'src/domains/root/commons/dialog';
import { Gateway } from 'src/domains/root/features/gateways/slice';
import { Text } from 'src/shared/ui';
import { Step } from './ContractTable';
import StripeCustomerUpdateDialog from './StripeCustomerUpdateDialog';
import { PostPaymentsParams } from './paymentSlice';
import InvoiceServiceDescription from './payment-method/InvoiceServiceDescription';

export default function ContractUpdateDialog({
  open,
  step,
  gateway,
  payments,
  submitting,
  handleStep,
  handleClose,
  handleSubmit,
  updateGatewaysPaymentsInvoceReview,
}: {
  open: boolean;
  step: Step;
  gateway: Gateway;
  payments: API.Payment[];
  submitting: boolean;
  handleStep: (step: Step) => void;
  handleClose: () => void;
  handleSubmit: (params: PostPaymentsParams) => void;
  updateGatewaysPaymentsInvoceReview: (gateways: Gateway[]) => void;
}) {
  return (
    <Dialog fullWidth open={open} loggingId="PaymentMethodSelectDialog">
      {step === 'payment-method' && (
        <PaymentMethodStep
          payments={payments}
          handleClose={handleClose}
          handleStep={handleStep}
        />
      )}
      {step === 'credit' && (
        <CreditCardStep
          open={open}
          gateway={gateway}
          handleClose={handleClose}
          updateGatewaysPaymentsInvoceReview={
            updateGatewaysPaymentsInvoceReview
          }
        />
      )}
      {step === 'invoice' && (
        <InvoiceStep
          submitting={submitting}
          gateway={gateway}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Dialog>
  );
}

function PaymentMethodStep({
  payments,
  handleClose,
  handleStep,
}: {
  payments: API.Payment[];
  handleClose: () => void;
  handleStep: (step: Partial<Step>) => void;
}) {
  const intl = useIntl();

  const isInvoiceEnabled =
    payments.find((payment) => payment.type === 'invoice')?.status ===
    'enabled';

  const [paymentMethod, setPaymentMethod] = useState<'credit' | 'invoice'>(
    isInvoiceEnabled ? 'invoice' : 'credit',
  );

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as 'credit' | 'invoice';
    setPaymentMethod(value);
  };

  const handleNext = () => {
    handleStep(paymentMethod);
  };

  return (
    <>
      <DialogTitle onClose={handleClose}>
        {intl.formatMessage({
          id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.title',
        })}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel htmlFor="payment-method">
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.paymentMethod.inputLabel',
            })}
          </InputLabel>
          <Select
            labelId="payment-method"
            label={intl.formatMessage({
              id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.paymentMethod.inputLabel',
            })}
            value={paymentMethod}
            onChange={handleChange}
            sx={{ height: 45, fieldset: { border: '1px solid #141414' } }}
          >
            <SelectMenuItem value="credit">
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.paymentMethod.select.credit',
              })}
            </SelectMenuItem>
            <SelectMenuItem value="invoice" disabled={!isInvoiceEnabled}>
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.paymentMethod.select.invoice',
              })}
            </SelectMenuItem>
          </Select>
        </FormControl>

        {paymentMethod === 'invoice' && (
          <Box pt={3}>
            <Typography fontSize={12}>
              請求書払いは、株式会社ネットプロテクションズの提供する「NP掛け払いサービス」を利用します。
            </Typography>
            <InvoiceServiceDescription size="small" />
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end" pt={2}>
          <Link
            href="/settings/others/contracts/payment-method"
            underline="none"
          >
            <Typography fontSize={12} fontWeight={700}>
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.paymentMethod.managementPageLink',
              })}
            </Typography>
          </Link>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          p: 3.5,
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
          gap: 1.5,
          '& > :not(:first-of-type)': {
            ml: 0,
          },
        }}
      >
        <CancelButton
          onClick={handleClose}
          sx={{ width: { xs: '100%', sm: 'unset' } }}
        />
        <PrimaryButton
          fullWidth
          onClick={handleNext}
          sx={{ width: { xs: '100%', sm: 'unset' } }}
        >
          {intl.formatMessage({
            id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.paymentMethod.submitButton',
          })}
        </PrimaryButton>
      </DialogActions>
    </>
  );
}

function CreditCardStep({
  open,
  gateway,
  handleClose,
  updateGatewaysPaymentsInvoceReview,
}: {
  open: boolean;
  gateway: Gateway;
  handleClose: () => void;
  updateGatewaysPaymentsInvoceReview: (gateways: Gateway[]) => void;
}) {
  return (
    <StripeCustomerUpdateDialog
      open={open}
      close={handleClose}
      gateway={gateway}
      updateGatewaysPaymentsInvoceReview={updateGatewaysPaymentsInvoceReview}
    />
  );
}

function InvoiceStep({
  gateway,
  submitting,
  handleClose,
  handleSubmit,
}: {
  gateway: Gateway;
  submitting: boolean;
  handleClose: () => void;
  handleSubmit: (params: PostPaymentsParams) => void;
}) {
  const intl = useIntl();

  const handlePayment = () => {
    handleSubmit({ cabiotPaymentType: 'npInvoice', gateways: [gateway] });
  };

  return (
    <>
      <DialogTitle onClose={handleClose}>
        {intl.formatMessage({
          id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.title',
        })}
      </DialogTitle>
      <DialogContent>
        <Text>
          【{gateway.name}】
          {intl.formatMessage({
            id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.invoice.description',
          })}
        </Text>
      </DialogContent>
      <DialogActions
        sx={{
          p: 3.5,
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
          gap: 1.5,
          '& > :not(:first-of-type)': {
            ml: 0,
          },
        }}
      >
        <NegativeButton
          onClick={handleClose}
          sx={{ width: { xs: '100%', sm: 'unset' } }}
        >
          {intl.formatMessage({ id: 'common.buttons.no' })}
        </NegativeButton>
        <PrimaryButton
          disabled={submitting}
          onClick={handlePayment}
          sx={{ width: { xs: '100%', sm: 'unset' } }}
        >
          {!submitting ? (
            intl.formatMessage({
              id: 'pages.OtherSetting.contracts.list.contractUpdateDialog.invoice.submitButton',
            })
          ) : (
            <CircularProgress color="inherit" size={20} />
          )}
        </PrimaryButton>
      </DialogActions>
    </>
  );
}

function SelectMenuItem({
  value,
  children,
  ...props
}: {
  value: string;
  children: React.ReactNode;
} & MenuItemProps) {
  return (
    <MenuItem
      value={value}
      {...props}
      sx={{
        p: 1,
        mx: 1,
        borderRadius: 1,

        ':hover': {
          backgroundColor: 'primary.light',
        },
        ':focus-visible': {
          backgroundColor: 'primary.light',
        },

        '&.Mui-selected': {
          backgroundColor: 'primary.main',
          color: 'white',
          fontWeight: 'bold',

          ':hover': {
            backgroundColor: 'primary.light',
          },
          ':focus-visible': {
            backgroundColor: 'primary.main',
          },
          ':focus-visible:hover': {
            backgroundColor: 'primary.light',
          },
        },
      }}
    >
      {children}
    </MenuItem>
  );
}
