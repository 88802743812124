import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import TextLink from './TextLink';
import InvoiceServiceDescription from './InvoiceServiceDescription';

export default function InvoiceTermsOfService({
  goStep,
}: {
  goStep: (to: 'back' | 'next') => void;
}) {
  const [checked, setChecked] = useState(false);

  const intl = useIntl();

  const handleChangeCheckbox = () => {
    setChecked((prev) => !prev);
  };

  const handleClickNext = () => {
    goStep('next');
  };

  return (
    <Box pt={1} width="100%">
      <Typography>
        {intl.formatMessage({
          id: 'pages.OtherSetting.contracts.payment-method.invoice.termsOfService.serviceInformation.0',
        })}
        <br />
        {intl.formatMessage({
          id: 'pages.OtherSetting.contracts.payment-method.invoice.termsOfService.serviceInformation.1',
        })}
      </Typography>

      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        rowGap={5}
        pt={5}
        pl={4}
      >
        <Box component="section">
          <Typography
            fontWeight="bold"
            pl={1.5}
            borderLeft={(theme) => `solid ${theme.palette.primary.main} 5px`}
          >
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.termsOfService.aboutInvoice.title',
            })}
          </Typography>

          <InvoiceServiceDescription />
        </Box>

        <Box component="section">
          <Typography
            fontWeight="bold"
            pl={1.5}
            borderLeft={(theme) => `solid ${theme.palette.primary.main} 5px`}
          >
            {intl.formatMessage({
              id: 'pages.OtherSetting.contracts.payment-method.invoice.termsOfService.cautionsForUse.title',
            })}
          </Typography>

          <Box py={2}>
            <Typography component="ul" pl={3}>
              <Typography component="li">
                請求書払いは、株式会社ネットプロテクションズへの債権譲渡によって行われます。請求書払いの有効化手続き後に契約更新されたCABIoTパックの契約更新料については、株式会社ネットプロテクションズの提供するNP掛け払いサービスが適用され、弊社は株式会社ネットプロテクションズに対して商品・サービスの代金債権を譲渡し、同社がお客様への請求元となります。
              </Typography>
              <Typography component="li">
                請求書は、請求書発行日に株式会社ネットプロテクションズより発行されます。取引翌月末までにお支払ください。弊社では請求書・領収書等の発行は行えません。
              </Typography>
              <Typography component="li">
                お客様指定様式（振込口座登録依頼書等）へのご対応はお受け致しかねますので、ご了承ください。
                請求書に記載されている銀行口座にお支払いください。
              </Typography>
              <Typography component="li">
                同一ワークスペースでの請求書払いによる契約更新は、請求書払いを有効化する際の手続き時に入力した会社情報が請求先となります。※請求額及び請求書送付先を分割することはできません。
              </Typography>
              <Typography component="li">
                請求書払いの有効化手続には、通常４営業日ほどお時間を頂戴しております。しかし、株式会社ネットプロテクションズの審査に時間がかかる場合や、当該審査の結果NP掛け払いサービスをご利用いただけない場合がございます。あらかじめご了承ください。
              </Typography>
              <Typography component="li">
                請求書払いの利用にあたっては、お客様に係る情報及びお客様の代表者等に係る個人情報を株式会社ネットプロテクションズに対して開示することに同意いただく必要があります。また、開示に同意いただく方が、開示対象者と異なる場合には、開示対象者の同意も得ているものとします。
              </Typography>
            </Typography>
          </Box>

          <Typography>なお、以下の内容もご確認ください。</Typography>

          <Box pt={2}>
            <Typography component="ul" pl={3}>
              <Typography component="li">
                日東工業の
                <TextLink href="https://www.nito.co.jp/privacy/">
                  プライバシーポリシー
                </TextLink>
              </Typography>
              <Typography component="li">
                株式会社ネットプロテクションズの
                <TextLink href="https://np-kakebarai.com/buy/terms/">
                  NP掛け払い利用規約
                </TextLink>
                及び同社の
                <TextLink href="https://corp.netprotections.com/privacypolicy/">
                  プライバシーポリシー
                </TextLink>
              </Typography>
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          rowGap={3}
        >
          <FormControlLabel
            control={<Checkbox sx={{ color: 'primary.main' }} />}
            label={
              <Typography>
                {intl.formatMessage({
                  id: 'pages.OtherSetting.contracts.payment-method.invoice.termsOfService.agreeToTermsOfService',
                })}
              </Typography>
            }
            onChange={handleChangeCheckbox}
          />
          <PrimaryButton
            fullWidth
            onClick={handleClickNext}
            disabled={!checked}
            sx={{ height: 44 }}
          >
            <Typography fontSize={18} fontWeight="bold">
              {intl.formatMessage({
                id: 'pages.OtherSetting.contracts.payment-method.invoice.termsOfService.moveToNextButton',
              })}
            </Typography>
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
}
