import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axios, { isAxiosError } from 'src/utils/api';
import { RootError, RootErrorResponse } from 'src/domains/root/utils/api';
import { RootState, Result } from 'src/domains/root/store';
import { Gateway } from '../gateways/slice';
import { activeWorkspaceSelector } from '../users/slice';

/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;

export type PaymentsErrorCode =
  | 'permission_denied'
  | 'admin_only'
  | 'not_found_device'
  | 'already_updated_contract'
  | 'failed_update_contract_because_np_invoice_activating'
  | 'already_review_np_invoice'
  | 'np_invoice_status_disabled'
  | 'np_invoice_transaction_registration_status_disabled'
  | 'creating_url'
  | 'contract_expired'
  | 'emergency_maintenance'
  | 'on_demo_site'
  | 'unknown_error';

interface Payment {
  url: string;
}

export type PostPaymentsParams = {
  cabiotPaymentType: 'stripe' | 'npInvoice';
  gateways: Gateway[];
  customerName?: string;
};

export const postPayments = createAsyncThunk<
  Payment,
  PostPaymentsParams & {
    token: Promise<string>;
  } & { isPaymentMethodFeatureEnabled: boolean },
  { state: RootState; rejectValue: RootError<PaymentsErrorCode> }
>('contracts/postPayments', async (params, thunkAPI) => {
  const token = await params.token;

  const workspace = activeWorkspaceSelector(thunkAPI.getState());
  if (!workspace) {
    throw new Error('No active workspace'); // active workspace が手に入らないのは異常系
  }

  try {
    const response = await axios.post<Payment>(
      `${API_ENDPOINT}/payments`,
      {
        workspaceId: workspace.workspaceId,
        devices: params.gateways.map(({ deviceId, contractExpiredAt }) => ({
          deviceId,
          contractExpiredAt,
        })),
        customerName: params.customerName,

        ...(params.isPaymentMethodFeatureEnabled && {
          cabiotPaymentType: params.cabiotPaymentType,
        }),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
    const paymentsError = getPostPaymentsError(error);
    return thunkAPI.rejectWithValue(paymentsError);
  }
});

const getPostPaymentsError = (
  error: AxiosError<RootErrorResponse>,
): RootError<PaymentsErrorCode> => {
  if (error.response) {
    const { status, data } = error.response;
    const { errorCode } = data ?? {};
    switch (status) {
      case 403:
        switch (errorCode) {
          case 'NOT_JOINED_WORKSPACE':
            return {
              code: 'permission_denied',
              noticeable: false,
              recoverable: false,
            };
          case 'PERMISSION_DENIED':
            return {
              code: 'admin_only',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: true,
              recoverable: true,
            };
        }
      case 404:
        return {
          code: 'not_found_device',
          noticeable: true,
          recoverable: false,
        };
      case 418: // デモサイト用のエラー
        return {
          code: 'on_demo_site',
          noticeable: true,
          recoverable: false,
        };
      case 409:
        switch (errorCode) {
          case 'FAILED_UPDATE_CONTRACT_BECAUSE_NP_INVOICE_ACTIVATING':
            return {
              code: 'failed_update_contract_because_np_invoice_activating',
              noticeable: false,
              recoverable: false,
            };
          case 'ALREADY_UPDATED_CONTRACT':
            return {
              code: 'already_updated_contract',
              noticeable: true,
              recoverable: true,
            };
          case 'ALREADY_REVIEW_NP_INVOICE':
            return {
              code: 'already_review_np_invoice',
              noticeable: true,
              recoverable: true,
            };
          case 'NP_INVOICE_STATUS_DISABLED':
            return {
              code: 'np_invoice_status_disabled',
              noticeable: true,
              recoverable: true,
            };
          case 'NP_INVOICE_TRANSACTION_REGISTRATION_STATUS_DISABLED':
            return {
              code: 'np_invoice_transaction_registration_status_disabled',
              noticeable: true,
              recoverable: true,
            };
          case 'CREATING_URL':
            return {
              code: 'creating_url',
              noticeable: true,
              recoverable: true,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: true,
              recoverable: true,
            };
        }
      case 410:
        return {
          code: 'contract_expired',
          noticeable: true,
          recoverable: true,
        };
      case 503:
        switch (errorCode) {
          case 'EMERGENCY_MAINTENANCE':
            return {
              code: 'emergency_maintenance',
              noticeable: false,
              recoverable: false,
            };
          default:
            return {
              code: 'unknown_error',
              noticeable: true,
              recoverable: true,
            };
        }
      default:
        return {
          code: 'unknown_error',
          noticeable: true,
          recoverable: true,
        };
    }
  }
  if (error.request) {
    return {
      code: 'unknown_error',
      noticeable: true,
      recoverable: true,
    };
  }
  return {
    code: 'unknown_error',
    noticeable: true,
    recoverable: true,
  };
};

interface PaymentsState {
  createResult: Result<Payment, RootError<PaymentsErrorCode>>;
  showInvoiceSuccessMessage: boolean;
}

const initialState: PaymentsState = {
  createResult: {
    data: null,
    error: null,
    status: 'idle',
  },
  showInvoiceSuccessMessage: false,
};

export const paymentsSlice = createSlice({
  name: 'contracts/payments',
  initialState,
  reducers: {
    resetCreateResult(state) {
      state.createResult.data = null;
      state.createResult.error = null;
      state.createResult.status = 'idle';
    },
    updateShowInvoiceSuccessMessage: (state, action) => {
      state.showInvoiceSuccessMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPayments.pending, (state) => {
        state.createResult.data = null;
        state.createResult.status = 'loading';
        state.createResult.error = null;
      })
      .addCase(postPayments.fulfilled, (state, action) => {
        state.createResult.data = action.payload;
        state.createResult.status = 'succeeded';
        state.createResult.error = null;
      })
      .addCase(postPayments.rejected, (state, action) => {
        state.createResult.data = null;
        state.createResult.status = 'failed';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.createResult.error = action.payload!;
      });
  },
});

export const createResultSelector = createSelector(
  (state: RootState) => state.payments,
  (state: PaymentsState) => state.createResult,
);

export const showInvoiceSuccessMessageSelector = createSelector(
  (state: RootState) => state.payments,
  (state: PaymentsState) => state.showInvoiceSuccessMessage,
);

export const { resetCreateResult, updateShowInvoiceSuccessMessage } =
  paymentsSlice.actions;

export default paymentsSlice.reducer;
