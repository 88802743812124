import { useSearchParams } from 'react-router-dom';
import {
  FEATURE_FLAG,
  FEATURE_FLAG_SESSION_STORAGE_KEY,
  QUERY_STRING_FEATURE_FLAG,
} from 'src/constants';

type Flag = keyof typeof FEATURE_FLAG;
type QueryStringFlag = keyof typeof QUERY_STRING_FEATURE_FLAG;

interface Props {
  flag: Flag | ((flag: typeof FEATURE_FLAG) => boolean);
  children: React.ReactNode | ((isEnabled: boolean) => React.ReactNode);
}

/*
 * @NOTE: このコンポーネントはまだ複数のfeature flagの対応がされていないため、
 *         今後複数のfeature-flagを扱う場合は、その対応が必要です。
 * */
export default function FeatureFlag({ flag, children }: Props) {
  const [featureFlagParam, featureFlagSessionStorage] = useGetFlags();

  if (featureFlagParam) {
    return renderChildren(flagByParam(featureFlagParam), children);
  }

  if (featureFlagSessionStorage) {
    return renderChildren(
      flagBySessionStorage(featureFlagSessionStorage),
      children,
    );
  }

  const isEnabled = evaluateFlag(flag);

  return renderChildren(isEnabled, children);
}

export function useCheckFeatureFlag(
  flag: Flag | ((flag: typeof FEATURE_FLAG) => boolean),
) {
  const [featureFlagParam, featureFlagSessionStorage] = useGetFlags();

  if (featureFlagParam) {
    return flagByParam(featureFlagParam);
  }

  if (featureFlagSessionStorage) {
    return flagBySessionStorage(featureFlagSessionStorage);
  }

  return evaluateFlag(flag);
}

function evaluateFlag(
  flag: Flag | ((flag: typeof FEATURE_FLAG) => boolean),
): boolean {
  if (typeof flag === 'function') {
    return flag(FEATURE_FLAG);
  }

  return FEATURE_FLAG[flag];
}

function renderChildren(
  isEnabled: boolean,
  children: React.ReactNode | ((isEnabled: boolean) => React.ReactNode),
) {
  if (typeof children === 'function') {
    return children(isEnabled);
  }

  return isEnabled ? children : null;
}

function useGetFlags(): [string | null, QueryStringFlag] {
  const [searchParams] = useSearchParams();
  const featureFlagParam = searchParams.get('featureFlag');

  const featureFlagSessionStorage =
    sessionStorage &&
    (sessionStorage.getItem(
      FEATURE_FLAG_SESSION_STORAGE_KEY,
    ) as QueryStringFlag);

  return [featureFlagParam, featureFlagSessionStorage];
}

function flagByParam(param: string) {
  const [key, status] = param.split('.');

  if (status === 'on') {
    sessionStorage.setItem(FEATURE_FLAG_SESSION_STORAGE_KEY, key);
    return true;
  } else {
    sessionStorage.removeItem(FEATURE_FLAG_SESSION_STORAGE_KEY);
    return false;
  }
}

function flagBySessionStorage(value: keyof typeof QUERY_STRING_FEATURE_FLAG) {
  const flag = QUERY_STRING_FEATURE_FLAG[value];

  if (flag) {
    return true;
  } else {
    return false;
  }
}
