import React, { useEffect } from 'react';
import { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
  CircularProgress,
} from '@mui/material';
import DialogCloseIcon from 'src/domains/root/commons/dialog/title/CloseIcon';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import SecondaryButton from 'src/domains/root/commons/buttons/Secondary';
import { useLocation } from 'react-router-dom';
import { awsRum } from 'src/utils/rum';
import ChartContextMenu from './ChartContextMenu';

const dialogCloseIconButtonStyle = css`
  height: 36px;
  padding: ${(props) => props.theme.spacing(2.25)} 0;
  right: 27px;
  top: 25px;
  width: 36px;
  z-index: 1000;
`;

const dialogCloseIconStyle = css`
  color: ${(props) => props.theme.palette.primary.main};
`;

type FullSensorDataButtonProps = {
  clicked: boolean;
  fetched: boolean;
  onClick: () => void;
};

interface Props {
  /** 全期間データ取得ボタンの表示が不要な場合は undefinedを指定する */
  handleFullSensorButton?: FullSensorDataButtonProps;
  handleClose: () => void;
  menuContents:
    | { show: false }
    | {
        show: true;
        csvDownload?: {
          onClick: () => void;
        };
      };
  children: React.ReactNode;
}

export default function SensorDataModalFrame({
  handleFullSensorButton,
  handleClose,
  menuContents,

  children,
}: Props) {
  const isSmallScreen = useSmallScreen();

  const location = useLocation();
  useEffect(() => {
    awsRum().then((rum) => {
      rum.recordPageView(location.pathname + '#SensorDataModalFrameRender');
    });
  }, [location]);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll="body"
      maxWidth="lg"
      fullWidth
      aria-labelledby="chart-dialog"
      aria-describedby="chart-dialog"
      fullScreen={isSmallScreen}
      PaperProps={{
        sx: {
          margin: 2,
        },
      }}
    >
      <Box
        display="flex"
        position="relative"
        justifyContent="flex-end"
        width="100%"
        height="0px"
        sx={{
          zIndex: 1001,
        }}
      >
        {handleFullSensorButton && (
          <Box position="relative" top={30} right={55}>
            <FullSensorDataButton {...handleFullSensorButton} />
          </Box>
        )}
        <Box>
          <IconButton
            onClick={handleClose}
            css={dialogCloseIconButtonStyle}
            size="large"
            aria-label="close-modal"
          >
            <DialogCloseIcon css={dialogCloseIconStyle} />
          </IconButton>
          {menuContents.show && (
            <Box position="relative" top={32} right={26}>
              {menuContents.csvDownload && (
                <ChartContextMenu
                  onClickCsvDownload={menuContents.csvDownload.onClick}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <DialogContent sx={{ height: '100%', px: isSmallScreen ? 0 : 3, py: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          pt={{ xs: 3, sm: 0 }}
        >
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function FullSensorDataButton({
  clicked,
  fetched,
  onClick: handleClick,
}: FullSensorDataButtonProps) {
  const intl = useIntl();

  return (
    <SecondaryButton
      onClick={handleClick}
      disabled={clicked}
      style={{
        height: 24,
      }}
    >
      {clicked && !fetched && (
        <Box display="flex" pr={0.5}>
          <CircularProgress size={12} thickness={8} />
        </Box>
      )}
      {intl.formatMessage({
        id:
          clicked && fetched
            ? 'sensorDataChart.fullSensorDataButton.done'
            : 'sensorDataChart.fullSensorDataButton.yet',
      })}
    </SecondaryButton>
  );
}
